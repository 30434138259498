/* .icon-menu:before {
    content: "\e601";
    color: #fff !important
  } */
.Eclispedot {
  /* display: inline-block; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;
  width: 150px;
}
/* 
#DemandTable th {
  min-width: 120px !important;
} */

#DemandTable td {
  /* text-align: center; */
}

/* #candidateProfiletable th {
  min-width: 120px !important;
} */


.rowCount {
  width: fit-content;
  float: left;
  /* color: wheat; */
  text-align: center;
  /* width: 100%; */
  margin-top: 30px;
  display: inline-block;
  padding: 0 0 0 40px;
}

.rowCount span {
  color: black;
  /*width: 80%;*/
  display: flex;
  flex-wrap: wrap;
  background: #dbdce3;
  padding: 20px;
  border-radius: 50px;
  box-shadow: 0px 10px 15px rgb(0 0 0 / 10%);
  height: 64px;
}


.pagination {
  width: fit-content !important;
  float: right;
  padding: 0 40px 0 0;
  margin-bottom: 21px;
}

.pagination ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: #dbdce3;
  padding: 8px;
  border-radius: 50px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
}

.pagination ul li {
  color: black;
  list-style: none;
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease;
}

.pagination ul li.numb {
  list-style: none;
  height: 45px;
  width: 45px;
  margin: 0 3px;
  line-height: 45px;
  border-radius: 50%;
}

.pagination ul li.numb.first {
  margin: 0px 3px 0 -5px;
}

.pagination ul li.numb.last {
  margin: 0px -5px 0 3px;
}

.pagination ul li.dots {
  font-size: 22px;
  cursor: default;
}

.pagination ul li.btn {
  padding: 0 20px;
  border-radius: 50px;
}

.pagination li.active,
.pagination ul li.numb:hover,
.pagination ul li:first-child:hover,
.pagination ul li:last-child:hover {
  color: #fff;
  background: #67ae67;
}

.ReactToolTip {
  width: 300px;

}

.hide {
  display: none;
}

.pointer {
  pointer-events: none;
  opacity: 0.3;

}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;

}

.main-panel > .content {
  
  background: #f9fbfd;

}
#AddBulkUpload{
  padding-right: 0 !important;
}
#AddBulkUpload table{
  width: 100%;
}

/* #AddBulkUpload table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
} */

#FileDataTable td {
  border: 1px solid black !important;
  border-collapse: collapse;
  padding:  0 !important;
}

datalist {
  position: absolute;
  max-height: 20em;
  border: 0 none;
  overflow-x: hidden;
  overflow-y: auto;
}

datalist option {
  font-size: 0.8em;
  padding: 0.3em 1em;
  background-color: #ccc;
  cursor: pointer;
}

/* option active styles */
datalist option:hover, datalist option:focus {
  color: #fff;
  background-color: #036;
  outline: 0 none;
}

.None {
  pointer-events:  none;
  display: none;
}

.TextRed{
  color: red;
}

.Textgreen{
  color: green;
}

#FileDataTable .TableTr  td {
  text-align: center !important;
  font-weight: 600;
}

#DemandTable th:first-child,#DemandTable tbody tr.odd td:first-child, #DemandTable tbody tr.odd  td:nth-child(2),#DemandTable tbody tr.odd  td:last-child {
 background-color:#e6e6e6 !important;
} 


#DemandTable tbody tr.even td:first-child, #DemandTable tbody tr.even  td:nth-child(2), #DemandTable tbody tr.even  td:last-child {
  background-color:#fff !important;
 } 

 /* #DemandTable thead th:nth-child(2){
  background-color:#583636 !important;
 }
  */

/* #DemandTable tbody tr.odd td:first-child {
  background-color: rgb(236, 236, 236) !important;
 } 

 #DemandTable tbody tr.odd  td:nth-child(2) {
  background-color: rgb(236, 236, 236) !important;

 }  */
 /* #DemandTable tbody tr.even td:first-child  ,#DemandTable tbody tr.even  td:nth-child(1) {
  background-color: rgb(236, 236, 236) !important;
 }  */



/* 

 #DemandTable .odd th:nth-child(2) {
  background-color: rgb(236, 236, 236) !important;
 } 

#DemandTable tbody .odd tr td:nth-child(2) {
  background-color: rgb(236, 236, 236) !important;
 } 

 
 #DemandTable .even th:first-child, #DemandTable th:nth-child(1) {
  background-color:rgb(236, 236, 236) !important;
 } 
 
 
 #DemandTable .even td:first-child,#DemandTable td:nth-child(1) {
   background-color: rgb(236, 236, 236) !important;
  } 
 
  #DemandTable .even th:nth-child(2) {
   background-color: rgb(236, 236, 236) !important;
  } 
 
 #DemandTable tbody .even tr td:nth-child(2) {
   background-color: rgb(236, 236, 236) !important;
  }  */
 
.dtfc-fixed-left, .dtfc-fixed-right{
  background: white;
  margin: 0 !important;
    padding: 0 !important;
    z-index: 1;
}


.subTable  {
  border-collapse: collapse !important;
  /* padding: 8px !important; */
  width: 100%;
}
.subTable th {
  background: #ccc !important;
}

.subTable th,.subTable td {
  border: 1px solid #ccc !important;
  /* padding: 8px !important; */
}

.subTable tr:nth-child(even) {
  background: #efefef !important;
}

.subTable tr:hover {
  background: #d1d1d1 !important;
}
@media (max-width: 768px) {
  #LogoImage {
      display: flex;
      justify-content: flex-start;
  }
}

