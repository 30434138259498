html {

  background: #f9fbfd !important;

}

.loader {
  position: relative;
  width: 100px;
  height: 100px;
}

.loader:before {
  content: '';
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  top: 50%;
  left: 0;
  transform: translate(-5px, -50%);
  background: linear-gradient(to right, #0d329f 50%, #039BE5 50%) no-repeat;
  background-size: 200% auto;
  background-position: 100% 0;
  animation: colorBallMoveX 1.5s linear infinite alternate;
}

.loader:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 2px;
  height: 100%;
  background: #039BE5;
}

@keyframes colorBallMoveX {
  0% {
    background-position: 0% 0;
    transform: translate(-15px, -50%);
  }

  15%,
  25% {
    background-position: 0% 0;
    transform: translate(0px, -50%);
  }

  75%,
  85% {
    background-position: 100% 0;
    transform: translate(50px, -50%);
  }

  100% {
    background-position: 100% 0;
    transform: translate(65px, -50%);
  }
}



.loaderdot {
  width: 32px;
  height: 32px;
  position: relative;
  border-radius: 50%;
  color: #039BE5;
  animation: fill 1s ease-in infinite alternate;
}

.loaderdot::before,
.loaderdot::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  left: 48px;
  top: 0;
  animation: fill 0.9s ease-in infinite alternate;
}

.loaderdot::after {
  left: auto;
  right: 48px;
  animation-duration: 1.1s;
}

@keyframes fill {
  0% {
    box-shadow: 0 0 0 2px inset
  }

  100% {
    box-shadow: 0 0 0 10px inset
  }
}


/* loader up down */
.loaderUpDown {
  width: 84px;
  height: 84px;
  position: relative;
  overflow: hidden;
}

.loaderUpDown:before,
.loaderUpDown:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: #039BE5;
  transform: translate(-50%, 100%) scale(0);
  animation: push 2s infinite ease-in;
}

.loaderUpDown:after {
  animation-delay: 1s;
}

@keyframes push {
  0% {
    transform: translate(-50%, 100%) scale(1);
  }

  15%,
  25% {
    transform: translate(-50%, 50%) scale(1);
  }

  50%,
  75% {
    transform: translate(-50%, -30%) scale(0.5);
  }

  80%,
  100% {
    transform: translate(-50%, -50%) scale(0);
  }
}

/* shin loadr start */
.loadershin {
  width: 48px;
  height: 48px;
  position: relative;
}

.loadershin::before,
.loadershin::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 48em;
  height: 48em;
  background-image:
    radial-gradient(circle 10px, #039BE5 100%, transparent 0),
    radial-gradient(circle 10px, #039BE5 100%, transparent 0),
    radial-gradient(circle 10px, #039BE5 100%, transparent 0),
    radial-gradient(circle 10px, #039BE5 100%, transparent 0),
    radial-gradient(circle 10px, #0d329f 100%, transparent 0),
    radial-gradient(circle 10px, #0d329f 100%, transparent 0),
    radial-gradient(circle 10px, #0d329f 100%, transparent 0),
    radial-gradient(circle 10px, #0d329f 100%, transparent 0);
  background-position: 0em -18em, 0em 18em, 18em 0em, -18em 0em,
    13em -13em, -13em -13em, 13em 13em, -13em 13em;
  background-repeat: no-repeat;
  font-size: 0.5px;
  border-radius: 50%;
  animation: blast 1s ease-in infinite;
}

.loadershin::after {
  font-size: 1px;
  background: #0d329f;
  animation: bounce 1s ease-in infinite;
}

@keyframes bounce {

  0%,
  100% {
    font-size: 0.75px
  }

  50% {
    font-size: 1.5px
  }
}

@keyframes blast {

  0%,
  40% {
    font-size: 0.5px;
  }

  70% {
    opacity: 1;
    font-size: 4px;
  }

  100% {
    font-size: 6px;
    opacity: 0;
  }
}

/* shin loadr end */


/* rotate loader start  */
.loaderRotate {
  width: 64px;
  height: 64px;
  position: relative;
  border-radius: 50%;
  box-shadow: -10px 8px 0 18px inset #0d329f;
  animation: rotateloaderRotate 2s ease-in infinite alternate;
}

.loaderRotate::before {
  content: '';
  position: absolute;
  left: 14px;
  bottom: 16px;
  background: #039BE5;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  animation: scale 1s ease-in infinite alternate;
}

@keyframes rotateloaderRotate {
  100% {
    transform: rotate(750deg)
  }
}

@keyframes scale {
  100% {
    transform: scale(0.5) translateY(5px)
  }
}

/* rotate loader END  */

/* ball up and down loader start */
.loaderballupdown {
  position: relative;
  width: 15px;
  height: 64px;
}

.loaderballupdown::after,
.loaderballupdown::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  color: #039BE5;
  background: currentColor;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  box-shadow: 45px 0, -45px 0;
  animation: move 0.5s linear infinite alternate;
}

.loaderballupdown::before {
  top: 100%;
  box-shadow: 50px 0;
  left: -25px;
  animation-direction: alternate-reverse;
}

@keyframes move {
  0% {
    top: 0
  }

  50% {
    top: 50%
  }

  100% {
    top: 100%
  }
}

/* ball up and down loader end */

/* loading loader start  */
.loaderloading {
  display: block;
  position: relative;
  height: 12px;
  width: 80%;
  border: 1px solid #0d329f;
  border-radius: 10px;
  overflow: hidden;
}

.loaderloading:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
  background: #039BE5;
  animation: 6s prog ease-in infinite;
}

@keyframes prog {
  to {
    width: 100%;
  }
}

/* loading loader end  */

/* step loader start */
.loaderstep {
  position: relative;
  width: 120px;
  height: 90px;
  margin: 0 auto;
}

.loaderstep:before {
  content: "";
  position: absolute;
  bottom: 30px;
  left: 50px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #039BE5;
  animation: loading-bounce 0.5s ease-in-out infinite alternate;
}

.loaderstep:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 7px;
  width: 45px;
  border-radius: 4px;
  box-shadow: 0 5px 0 #0d329f, -35px 50px 0 #0d329f, -70px 95px 0 #0d329f;
  animation: loading-step 1s ease-in-out infinite;
}

@keyframes loading-bounce {
  0% {
    transform: scale(1, 0.7)
  }

  40% {
    transform: scale(0.8, 1.2)
  }

  60% {
    transform: scale(1, 1)
  }

  100% {
    bottom: 140px
  }
}

@keyframes loading-step {
  0% {
    box-shadow: 0 10px 0 rgba(0, 0, 0, 0),
      0 10px 0 #0d329f,
      -35px 50px 0 #039BE5,
      -70px 90px 0 #0d329f;
  }

  100% {
    box-shadow: 0 10px 0 #0d329f,
      -35px 50px 0 #039BE5,
      -70px 90px 0 #0d329f,
      -70px 90px 0 rgba(0, 0, 0, 0);
  }
}

/* step loader end */
/* loader sisa start */
.loadersisa {
  display: block;
  position: relative;
  height: 32px;
  width: 120px;
  border-bottom: 5px solid rgb(206, 11, 11);
  box-sizing: border-box;
  animation: balancingloadersisa 2s linear infinite alternate;
  transform-origin: 50% 100%;
}

.loadersisa:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #039BE5;
  animation: ballbnsloadersisa 2s linear infinite alternate;
}

.loadersisa:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 20px;
  width: 20px;
  transform: translate(-50%, 100%);
  border-radius: 50%;
  border: 6px solid rgb(164, 16, 16);
}

@keyframes ballbnsloadersisa {
  0% {
    left: 0;
    transform: translateX(0%);
  }

  100% {
    left: 100%;
    transform: translateX(-100%);
  }
}

@keyframes balancingloadersisa {
  0% {
    transform: rotate(-15deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(15deg);
  }
}

/* loader sisa end */



/* half circle loader start */
.loaderhalfcircle {
  width: 64px;
  height: 64px;
  position: relative;
  animation: rotate 1.5s ease-in infinite alternate;
}

.loaderhalfcircle::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  color: #039BE5;
  background: currentColor;
  width: 64px;
  height: 32px;
  border-radius: 0 0 50px 50px;
}

.loaderhalfcircle::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 10%;
  background: rgb(164, 16, 16);
  width: 8px;
  height: 64px;
  animation: rotateloaderhalfcircle 1.2s linear infinite alternate-reverse;
}

@keyframes rotateloaderhalfcircle {
  100% {
    transform: rotate(360deg)
  }
}

/* half circle loader end */


.fontstar {
  font-size: 37px;
  position: relative;
  left: 6px;
  top: 6px;
  color: #5e5315;
}

hr.new5 {
  border: 5px solid #0322e5;
  border-radius: 5px;
  position: relative;
  bottom: 15px;
}



/* refresh loader start */
.loaderrefresh {
  width: 48px;
  height: 48px;
  border: 3px solid #039BE5;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotationloaderrefresh 1s linear infinite;
}

.loaderrefresh::after {
  content: '';
  position: absolute;
  box-sizing: border-box;
  left: 20px;
  top: 31px;
  border: 10px solid transparent;
  border-right-color: #0322e5;
  transform: rotate(-40deg);
}

@keyframes rotationloaderrefresh {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* refresh loader end */


/* cube Loader start */
.loadercube {
  width: 64px;
  height: 64px;
  position: relative;
  background-image:
    linear-gradient(#1269db 16px, transparent 0),
    linear-gradient(#777f86 16px, transparent 0),
    linear-gradient(#777f86 16px, transparent 0),
    linear-gradient(#1269db 16px, transparent 0);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: left top, left bottom, right top, right bottom;
  animation: rotateloadercube 1s linear infinite;
}

@keyframes rotateloadercube {
  0% {
    width: 64px;
    height: 64px;
    transform: rotate(0deg)
  }

  50% {
    width: 30px;
    height: 30px;
    transform: rotate(180deg)
  }

  100% {
    width: 64px;
    height: 64px;
    transform: rotate(360deg)
  }
}

/* cube Loader end */


/* ninja loader start */
.loaderninja {
  width: 96px;
  box-sizing: content-box;
  height: 48px;
  background: #FFF;
  border-color: #039BE5;
  border-style: solid;
  border-width: 2px 2px 50px 2px;
  border-radius: 100%;
  position: relative;
  animation: 3s yinYangloaderninja linear infinite;
}

.loaderninja:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  background: #FFF;
  border: 18px solid #039BE5;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  box-sizing: content-box;
}

.loaderninja:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background: #039BE5;
  border: 18px solid #FFF;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  box-sizing: content-box;
}

@keyframes yinYangloaderninja {
  100% {
    transform: rotate(360deg)
  }
}

/* ninja loader end */

/* pencile .loader start */
.loaderpencile {
  position: relative;
  height: 200px;
  width: 200px;
  border-bottom: 3px solid #039BE5;
  box-sizing: border-box;
  animation: drawLineloaderpencile 4s linear infinite;
}

.loaderpencile:before {
  content: "";
  position: absolute;
  left: calc(100% + 14px);
  bottom: -6px;
  width: 16px;
  height: 100px;
  border-radius: 20px 20px 50px 50px;
  background-repeat: no-repeat;
  background-image: linear-gradient(#039BE5 6px, transparent 0),
    linear-gradient(45deg, rgba(0, 0, 0, 0.02) 49%, white 51%),
    linear-gradient(315deg, #00000005 49%, white 51%),
    linear-gradient(to bottom, #ff3d00 10%, #039BE5 10%, #039BE5 90%, #ff3d00 90%);
  background-size: 3px 3px, 8px 8px, 8px 8px, 16px 88px;
  background-position: center bottom, left 88px, right 88px, left top;
  transform: rotate(25deg);
  animation: pencilRotloaderpencile 4s linear infinite;
}

@keyframes drawLineloaderpencile {

  0%,
  100% {
    width: 0px
  }

  45%,
  55% {
    width: 200px
  }
}

@keyframes pencilRotloaderpencile {

  0%,
  45% {
    bottom: -6px;
    left: calc(100% + 14px);
    transform: rotate(25deg);
  }

  55%,
  100% {
    bottom: -12px;
    left: calc(100% + 16px);
    transform: rotate(220deg);
  }
}

.loader-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.73);


  z-index: 9999;
}

/* pencile .loader end */

/* sponge .loader start */
.loadersponge {
  width: 48px;
  height: 48px;
  margin: auto;
  position: relative;
}

.loadersponge:before {
  content: '';
  width: 48px;
  height: 5px;
  background: #000;
  opacity: 0.25;
  position: absolute;
  top: 60px;
  left: 0;
  border-radius: 50%;
  animation: shadowloadersponge 0.5s linear infinite;
}

.loadersponge:after {
  content: '';
  width: 100%;
  height: 100%;
  background: #ff3d00;
  animation: bxSpinloadersponge 0.5s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
}

@keyframes bxSpinloadersponge {
  17% {
    border-bottom-right-radius: 3px;
  }

  25% {
    transform: translateY(9px) rotate(22.5deg);
  }

  50% {
    transform: translateY(18px) scale(1, .9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }

  75% {
    transform: translateY(9px) rotate(67.5deg);
  }

  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@keyframes shadowloadersponge {

  0%,
  100% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1);
  }
}

/* sponge .loader end */

/* cross loader start*/
.loadercross {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #039BE5;
}

.loadercross:before,
.loadercross:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spinloadercross linear infinite;
}

.loadercross:after {
  color: #FF3D00;
  transform: rotateY(70deg);
  animation-delay: .4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spinloadercross {

  0%,
  100% {
    box-shadow: .2em 0px 0 0px currentcolor;
  }

  12% {
    box-shadow: .2em .2em 0 0 currentcolor;
  }

  25% {
    box-shadow: 0 .2em 0 0px currentcolor;
  }

  37% {
    box-shadow: -.2em .2em 0 0 currentcolor;
  }

  50% {
    box-shadow: -.2em 0 0 0 currentcolor;
  }

  62% {
    box-shadow: -.2em -.2em 0 0 currentcolor;
  }

  75% {
    box-shadow: 0px -.2em 0 0 currentcolor;
  }

  87% {
    box-shadow: .2em -.2em 0 0 currentcolor;
  }
}

/* cross loader end*/


/* animation star start */
#stars3:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 369px 2293px #FFF, 231px 1157px #FFF, 1182px 853px #FFF, 1692px 1762px #FFF, 1549px 455px #FFF, 669px 258px #FFF, 279px 1655px #FFF, 537px 874px #FFF, 1307px 1908px #FFF, 1683px 1456px #FFF, 1116px 1175px #FFF, 1096px 849px #FFF, 1840px 1977px #FFF, 1425px 1180px #FFF, 477px 1968px #FFF, 1603px 476px #FFF, 1887px 1575px #FFF, 1182px 665px #FFF, 710px 147px #FFF, 703px 1933px #FFF, 132px 205px #FFF, 1285px 934px #FFF, 289px 983px #FFF, 1275px 1863px #FFF, 295px 1697px #FFF, 915px 204px #FFF, 232px 1838px #FFF, 398px 1165px #FFF, 1902px 1855px #FFF, 166px 1209px #FFF, 206px 661px #FFF, 1773px 1473px #FFF, 1509px 646px #FFF, 1162px 808px #FFF, 411px 153px #FFF, 1831px 1447px #FFF, 1226px 701px #FFF, 1697px 80px #FFF, 1904px 370px #FFF, 91px 395px #FFF, 54px 627px #FFF, 1421px 1222px #FFF, 1007px 1029px #FFF, 754px 1601px #FFF, 434px 1182px #FFF, 1318px 650px #FFF, 1102px 1537px #FFF, 817px 1996px #FFF, 1957px 754px #FFF, 506px 800px #FFF, 1057px 1614px #FFF, 1345px 25px #FFF, 1183px 877px #FFF, 1947px 1635px #FFF, 631px 1247px #FFF, 38px 1057px #FFF, 1651px 1892px #FFF, 1450px 215px #FFF, 120px 1406px #FFF, 54px 593px #FFF, 885px 898px #FFF, 1737px 862px #FFF, 608px 488px #FFF, 1377px 1851px #FFF, 1209px 1218px #FFF, 1964px 508px #FFF, 1110px 1709px #FFF, 731px 1329px #FFF, 140px 1872px #FFF, 1936px 814px #FFF, 1294px 87px #FFF, 1223px 1426px #FFF, 1563px 1383px #FFF, 492px 781px #FFF, 1979px 637px #FFF, 1988px 341px #FFF, 1004px 1746px #FFF, 101px 1064px #FFF, 136px 1603px #FFF, 1360px 593px #FFF, 1699px 1231px #FFF, 1390px 1370px #FFF, 299px 205px #FFF, 1344px 1862px #FFF, 1816px 440px #FFF, 924px 1169px #FFF, 469px 489px #FFF, 1699px 477px #FFF, 1125px 918px #FFF, 894px 313px #FFF, 803px 368px #FFF, 808px 1126px #FFF, 744px 1174px #FFF, 782px 1248px #FFF, 865px 1847px #FFF, 49px 1725px #FFF, 258px 16px #FFF, 464px 730px #FFF, 276px 888px #FFF, 1150px 1862px #FFF;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-2000px);
  }
}

html {
  height: 100%;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  /* overflow: hidden; */
}

/* animation star end */

.fontstar {
  /* margin: auto; */
  color: white;

}

.te {
  width: 15px;
  height: 56px;
  display: flex;
  justify-content: right;

  position: relative;
  left: 13px;

  animation: mymove 5s infinite;
}

@keyframes mymove {
  100% {
    transform: rotate(180deg);
  }
}


/* normal loader start */
.loadernormal {
  width: 48px;
  height: 48px;
  border: 5px solid #039BE5;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotationsloadernormal 1s linear infinite;
}

@keyframes rotationsloadernormal {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* normal loader end */