.list-group {
	.list-group-header {
		font-size: 12px;
		font-weight: 600;
		padding: .75rem 1rem;
	}
	.list-group-item {
		display: flex;
		align-items: stretch;
		border-width: 1px 0;
		border-color: #ebecec;
	}
	.list-group-item-figure {
		align-self: start;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		color: #a9acb0;
	}
	.list-group-item-body {
		flex: 1;
		min-width: 0;
		align-self: center;
		font-size: .875rem;
	}
	.list-group-item-text {
		margin-bottom: 0;
		line-height: 1.25rem;
		color: #686f76;
	}
}

.list-group-file-item {
	.list-group-item {
		padding: 0px;
	}
	.list-group-item-figure {
		padding: .75rem 1rem;
	}
	.list-group-item-body {
		padding: .75rem 0;
	}
}

.list-group-bordered {
	.list-group-item {
		border: 1px solid #e3ebf6;
		&.active {
			background: $primary-color;
			border-color: $primary-color;
		}
	}
}

.list-group-lg {
	.list-group-item {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}
}