.login body {
    /* //background-color: white */
}

.login .container {
    flex-wrap: wrap
}

.login .card {
    border: none;
    border-radius: 10px;
    background-color: #07afef;
    width: 300px;
    margin-top: -60px
        /*margin-bottom:0px;*/
}

.login .Top_Card {
    margin-bottom: 0px !important;
    /* margin-top: 50px !important; */
}

.login p.mb-1 {
    font-size: 35px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: aliceblue;
    margin-top: 12px;
}

.login .btn-primary {
    border: none;
    background: #5777DE;
    margin-bottom: 15px
}

.login .btn-primary small {
    color: #9FB7FD
}

.login .btn-primary span {
    font-size: 13px
}

.login .card.two {
    border-top-right-radius: 60px;
    border-top-left-radius: 0
}

.login .form-group {
    position: relative;
    margin-bottom: 0.75rem;
    padding: 0px;
}

.login .form-control {
    box-shadow: none;
    border-radius: 10px;
    outline: 0;
    margin-top: 7px;
    background-color: white;
    border-bottom: 1.5px solid #E6EBEE
}

.login .form-control:focus {
    box-shadow: none;
    background-color: white;
    border-radius: 10px;
    border-bottom: 2px solid #8A97A8
}




.login .form-control-placeholder {
    position: absolute;
    top: 15px;
    left: 10px;
    transition: all 200ms;
    background-color: white;
    opacity: 0.5;
    font-size: 80%
}

.login .form-control:focus+.form-control-placeholder,
.login .form-control:valid+.form-control-placeholder {
    font-size: 80%;
    transform: translate3d(0, -90%, 0);
    border-radius: 6px;
    opacity: 1;
    top: 10px;
    background-color: white;
}

.login .btn-block {
    border: none;
    border-radius: 8px;
    background-color: #506CCF;
    padding: 10px 0 12px
}

.login .btn-block:focus {
    box-shadow: none
}

.login .btn-block span {
    font-size: 15px;
    color: #D0E6FF
}

.login form span {
    color: red;
}

.login form .errMsg {
    color: red;
}

.login .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
}

.login .switch input {
    width: 0;
    height: 0;
    opacity: 0;
}

.login .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    transition: 0.4s;
    border-radius: 34px;
    box-shadow: -1px 1px 6px 0px rgb(0 0 0 / 68%);
}

.login .slider:before {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    background: #656464e8;
    left: 6px;
    bottom: 3px;
    border-radius: 50%;
    transition: 0.3s cubic-bezier(0.42, 0, 0, 1.68);
}

.login input:checked+.slider {
    background-color: #2196f3;
}

.login input:checked+.slider:before {
    transform: translateX(22px);
    background: yellow;
}


.login .eyeLogin {
    margin-left: -27px !important;
    padding: 7px 15px;
    background: none;
    color: black;
    border: 1px solid #dcd0d0;
    margin-top: 9px;
    border-radius: 9px;
}

@media screen and (max-width: 991px) {
    .login .Top_Card {
            margin-bottom: 0px !important;
            margin-top: 22px !important;
        }
}