/*     	Progress     */
.progress {
	border-radius: 100px;
	height: 14px;
	.progress-bar{
		border-radius: 100px;
	}
	&.progress-sm {
		height: 8px;
	}
	&.progress-lg {
		height: 20px;
	}
}